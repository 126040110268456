import type { CartOption } from "../../types"
import {
  getVariantByVariantOptions,
  getVariantOptionsByCartOptions,
  hasStock,
} from ".."
import type { Payload } from "@local/payload-client/src/types"

export const hasProductOptionValueStock = (
  product: Payload.Product,
  selectedOptions: CartOption[],
  optionToCheck: CartOption
): boolean => {
  const productVariants = product.variants
  if (!productVariants) return false

  const cartOptions = selectedOptions.map((so) => {
    if (so.optionName === optionToCheck.optionName) {
      return optionToCheck
    }
    return so
  })

  const productOptionValues = getVariantOptionsByCartOptions(
    cartOptions,
    product.options
  )
  const productVariant = getVariantByVariantOptions(
    productOptionValues,
    productVariants
  )

  if (!productVariant) return false
  return hasStock({ product, variant: productVariant })
}
