import type { Payload } from "@local/payload-client/src/types"

interface GetImageFromProductArgs {
  product: Payload.Product
}

export const getImageFromProduct = ({ product }: GetImageFromProductArgs) => {
  let imageToReturn = ""

  if (product?.bundleitem && product.bundleitem.length > 0) {
    imageToReturn = product.images?.[0]?.image.url
  }

  if (product && product.variants && product.variants.length > 0) {
    const variant = product.variants[0]
    if (variant && variant.image) {
      const image = variant.image

      if (image && image.url) {
        imageToReturn = image.url
      }
    }
  }

  if (
    !imageToReturn &&
    product &&
    product.options &&
    product.options.length > 0
  ) {
    const option = product.options[0]
    if (option && option.values && option.values.length > 0) {
      const value = option.values[0]

      if (value && value.images && value.images?.[0]?.image?.url) {
        imageToReturn = value.images?.[0]?.image?.url
      }
    }
  } else if (
    !imageToReturn &&
    product &&
    product.images &&
    product.images.length > 0
  ) {
    let image = product.images.filter((i) => i.type === "variant")?.[0]

    if (!image) {
      image = product.images.reverse()[0]
    }

    if (image && image.image && image.image.url) {
      imageToReturn = image.image.url
    }
  }

  return imageToReturn
}
