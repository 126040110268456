"use client"

import { Currency, useI10n } from "@local/i10n"
import { useCallback, useMemo } from "react"
import type { Payload } from "@local/payload-client/src/types"
import type {
  CartBundleItem,
  CartOption,
  CartItem,
  PersistentCart,
} from "../types"
import {
  getFirstVariantInStock,
  getInitialCartByProduct,
  getQuantitySelectorOptions,
  getProductOptionsFromProduct,
  hasProductOptionValueStock,
  isBundle,
  hasVariants,
  hasOptions,
  hasStock,
  getImageFromProducts,
  getPersistentCartByItems,
} from "../utils"
import { getSearchParamsByCartOptions } from "../utils/product/getSearchParamsByCartOptions"
import { useSearchParamsSelectorApp } from "./useSearchParamsSelectorApp"
import { IS_INSOLES } from "./isInsoles"

interface UseProductsArgs {
  mainProductId: Payload.Product["id"]
  products: Payload.Product[]
  region: string
  currency?: Currency
  ignoreSearchParams?: boolean
  priceOverride?: string
}

export const useProducts = ({
  mainProductId,
  products,
  region,
  currency,
  ignoreSearchParams,
  priceOverride,
}: UseProductsArgs) => {
  const productMap = useMemo(() => {
    const map = new Map<string, Payload.Product>()
    for (const p of products) {
      if (p) {
        if (p.bundleitem && p.bundleitem.length > 0) {
          for (const b of p.bundleitem) {
            if (b.product.id && !map.has(b.product.id)) {
              map.set(b.product.id, b.product)
            }
          }
        }
        if (p.related && p.related.length > 0) {
          for (const r of p.related) {
            if (r.id && !map.has(r.id)) {
              map.set(r.id, r)
            }
          }
        }
        map.set(p.id, p)
      }
    }
    return map
  }, [products])

  const { currencySymbol } = useI10n()
  const mainProduct = productMap.get(mainProductId)
  const isBundleProduct = isBundle(mainProduct!)
  const quantityOptions = getQuantitySelectorOptions({
    product: mainProduct!,
    currencySymbol,
    isBundle: isBundleProduct,
    currency,
  })

  const hasProductStock = useMemo(() => {
    if (mainProduct) {
      if (hasVariants(mainProduct) && hasOptions(mainProduct)) {
        return mainProduct.variants?.some((v) =>
          hasStock({ product: mainProduct, variant: v })
        )
      } else {
        return hasStock({ product: mainProduct })
      }
    }
    return false
  }, [mainProduct])

  const hasBundleStock = useMemo(() => {
    if (mainProduct && isBundleProduct) {
      return mainProduct.bundleitem?.every((b) => {
        const product = productMap.get(b.product.id)
        if (product) {
          if (hasVariants(product) && hasOptions(product)) {
            return product.variants?.some((v) =>
              hasStock({ product, variant: v })
            )
          } else {
            return hasStock({ product })
          }
        }
        return false
      })
    }
    return false
  }, [mainProduct, isBundleProduct, productMap])

  const { searchParams, pathname, pushSearchParamsSelector } =
    useSearchParamsSelectorApp()

  // HACK FOR INSOLES because we don't want to show the 1 quantity option
  if (IS_INSOLES && !pathname?.includes("order")) {
    quantityOptions.shift()
  }

  const getProductOptions = (productId: Payload.Product["id"]) => {
    const options = getProductOptionsFromProduct({
      productId,
      productMap,
      region,
    })
    return options
  }

  const getInitialCart = useCallback(() => {
    return getInitialCartByProduct(
      mainProduct!,
      productMap,
      quantityOptions &&
        quantityOptions.length > 0 &&
        quantityOptions[0] &&
        quantityOptions[0].value
        ? quantityOptions[0].value
        : 1,
      region,
      currency,
      null,
      priceOverride
    )
  }, [
    quantityOptions,
    productMap,
    mainProduct,
    region,
    currency,
    priceOverride,
  ])

  const getSearchParamsCart = useCallback(() => {
    const formattedSearchParams = new URLSearchParams(searchParams?.toString())

    if (!ignoreSearchParams || !formattedSearchParams) return null

    const cart = getInitialCartByProduct(
      mainProduct!,
      productMap,
      quantityOptions &&
        quantityOptions.length > 0 &&
        quantityOptions[0] &&
        quantityOptions[0].value
        ? quantityOptions[0].value
        : 1,
      region,
      currency,
      formattedSearchParams,
      priceOverride
    )

    if (cart.length <= 0) return null

    return cart
  }, [
    quantityOptions,
    productMap,
    mainProduct,
    region,
    currency,
    ignoreSearchParams,
    searchParams,
    priceOverride,
  ])

  const getPersistentCart = useCallback(
    (persistentCart: PersistentCart) => {
      const persistentCartproducts = persistentCart?.items.map(
        (item) => item.productId
      )

      if (
        !persistentCartproducts?.every((productId) => productMap.has(productId))
      ) {
        return null
      }

      const cart = getPersistentCartByItems(
        mainProduct!,
        persistentCart,
        productMap,
        region,
        currency,
        priceOverride
      )

      if (cart.length <= 0) return null

      // This is a fallback that detects in valid products in a cart and call it quits
      const acceptableProducts = new Set(products.map((item) => item.id))
      if (cart.some((item) => !acceptableProducts.has(item.productId))) {
        return null
      }

      return cart
    },
    [mainProduct, productMap, region, currency, priceOverride, products]
  )

  const getAvailableVariant = useCallback(
    (productId: Payload.Product["id"], selectedOptions?: CartOption[]) => {
      const product = productMap.get(productId)
      if (product) {
        return getFirstVariantInStock({
          product,
          region,
          selectedOptions,
          priceOverride,
        })
      }
      return null
    },
    [productMap, region, priceOverride]
  )

  const hasOptionValueStock = (
    productId: Payload.Product["id"],
    selectedOptions: CartOption[],
    optionToCheck: CartOption
  ) => {
    const product = productMap.get(productId)
    if (product) {
      return hasProductOptionValueStock(product, selectedOptions, optionToCheck)
    }
    return false
  }

  const hasVariantStock = (
    productId: Payload.Product["id"],
    variantId: Payload.ProductVariant["id"]
  ) => {
    const product = productMap.get(productId)
    if (product) {
      const variant = product.variants?.find((v) => v.id === variantId)
      if (variant) {
        return hasStock({ product, variant })
      }
    }
    return false
  }

  const getProductImage = (
    productId: Payload.Product["id"],
    variantId: Payload.ProductVariant["id"]
  ) => {
    return getImageFromProducts({ productId, variantId, productMap })
  }

  const triggerSearchParamsSelector = useCallback(
    (cartItem: CartItem | CartBundleItem) => {
      if (ignoreSearchParams) return

      const productOptions = getProductOptionsFromProduct({
        productId: cartItem.productId,
        productMap,
        region,
      })
      const searchParams = getSearchParamsByCartOptions(
        cartItem.options,
        productOptions
      )
      pushSearchParamsSelector(searchParams)
    },
    [pushSearchParamsSelector, productMap, region, ignoreSearchParams]
  )

  return {
    mainProduct,
    productMap,
    isBundleProduct,
    quantityOptions,
    hasProductStock,
    hasBundleStock,
    getProductImage,
    getProductOptions,
    getInitialCart,
    getSearchParamsCart,
    getPersistentCart,
    getAvailableVariant,
    hasOptionValueStock,
    hasVariantStock,
    triggerSearchParamsSelector,
  }
}
