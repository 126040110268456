"use client"

import type { Payload } from "@local/payload-client/src/types"
import { CartBundleItem, CartItem, PersistentCart } from "../../types"
import { Currency } from "@local/i10n"
import {
  hasOptions,
  hasVariants,
  getPricesFromProduct,
  getPrice,
  getRegionalVariantFromVariant,
  getCartOptionsByVariantOptions,
  getProductOptionsFromProduct,
  getFirstVariantInStock,
} from ".."

export const getPersistentCartByItems = (
  mainProduct: Payload.Product,
  persistentCart: PersistentCart,
  productMap: Map<Payload.Product["id"], Payload.Product>,
  region: string,
  currency?: Currency,
  priceOverride?: string
): CartItem[] => {
  const cartItems: CartItem[] = []
  const prices = getPricesFromProduct(mainProduct, currency)

  for (const item of persistentCart.items) {
    const product = productMap.get(item.productId)

    if (product) {
      if (product?.bundleitem && product.bundleitem.length > 0) {
        const bundleItems = [] as CartBundleItem[]
        product?.bundleitem.map((bi, i) => {
          const variant = getFirstVariantInStock({
            product: bi.product,
            region: region,
          }) as CartBundleItem

          bundleItems.push({
            ...variant,
            cartKey: i + 1,
          })
        })

        cartItems.push({
          bundleItems: bundleItems,
          basePrice: getPrice(1, prices).basePrice,
          cartKey: cartItems.length + 1,
          options: [],
          price: getPrice(1, prices).price,
          priceOverride: priceOverride,
          productId: product!.id,
          productName: product!.name,
          quantity: 1,
          sku: null,
        })
      } else {
        const variant = product!.variants?.find((v) => v.id === item.variantId)
        const regionalVariant = getRegionalVariantFromVariant(variant, region)

        if (
          variant &&
          regionalVariant &&
          hasVariants(product) &&
          hasOptions(product)
        ) {
          const productOptions = getProductOptionsFromProduct({
            productId: product.id,
            productMap: new Map<Payload.Product["id"], Payload.Product>([
              [product.id, product],
            ]),
            region,
          })
          const cartOptions = getCartOptionsByVariantOptions(
            productOptions,
            variant.options
          )

          cartItems.push({
            basePrice: getPrice(1, prices).basePrice,
            cartKey: cartItems.length + 1,
            giftWrap: variant.giftwrap || null,
            options: cartOptions,
            preOrder: regionalVariant.preorder ?? product.preorder ?? null,
            price: getPrice(1, prices).price,
            priceOverride: priceOverride,
            productId: product!.id,
            productName: product!.name,
            quantity: 1,
            sku: regionalVariant.sku || null,
            variantId: regionalVariant.id,
            variantName: regionalVariant.name,
          })
        } else {
          cartItems.push({
            basePrice: getPrice(1, prices).basePrice,
            cartKey: cartItems.length + 1,
            giftWrap: product.giftwrap || null,
            options: [],
            preOrder: product.preorder,
            price: getPrice(1, prices).price,
            priceOverride: priceOverride,
            productId: product.id,
            productName: product.name,
            quantity: 1,
            sku: product.sku || null,
          })
        }
      }
    }
  }

  return cartItems
}
