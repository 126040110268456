import { getImageFromProduct } from "./getImageFromProduct"
import type { Payload } from "@local/payload-client/src/types"

interface GetImageFromProductsArgs {
  productId: Payload.Product["id"]
  variantId?: Payload.ProductVariant["id"] | null
  productMap: Map<Payload.Product["id"], Payload.Product>
}

export const getImageFromProducts = ({
  productId,
  variantId,
  productMap,
}: GetImageFromProductsArgs): string => {
  const product = productMap.get(productId)

  let imageToReturn = ""

  if (product?.bundleitem && product.bundleitem.length > 0) {
    imageToReturn = product.images?.[0]?.image.url
  }

  if (!imageToReturn && product && product.variants) {
    const variant = product.variants.find((v) => v.id === variantId)

    if (variantId && product.variants) {
      if (variant && variant.image) {
        const image = variant.image
        if (image && image.url) {
          imageToReturn = image.url
        }
      }
    }

    if (!imageToReturn && variantId) {
      if (product.options && product.options.length > 0) {
        product.options.find((option) =>
          option.values.find((value) => {
            variant?.options.map((e) => {
              if (
                value.name === e.value &&
                value?.images &&
                value.images.length > 0
              ) {
                imageToReturn = value.images?.[0]?.image.url
                return true
              }
            })
          })
        )
      }
    }

    if (!imageToReturn) {
      imageToReturn = getImageFromProduct({ product })
    }
  }

  return imageToReturn
}
