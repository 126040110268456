"use client"

import { usePathname, useSearchParams, useRouter } from "next/navigation"
import { useCallback } from "react"

export const useSearchParamsSelectorApp = () => {
  const router = useRouter()
  const pathname = usePathname()
  const searchParams = useSearchParams()

  const createQueryString = useCallback(
    (options: { key: string; value: string }[]) => {
      const params = new URLSearchParams(searchParams?.toString())
      for (const { key, value } of options) {
        params.set(key, value)
      }
      return params.toString()
    },
    [searchParams]
  )

  const pushSearchParamsSelector = (
    options: { key: string; value: string }[]
  ) => {
    options = options.map((option) => ({
      key: option.key.toLowerCase(),
      value: option.value,
    }))
    if (!options || !options.length) return
    let apply = false
    for (const { key, value } of options) {
      if (searchParams?.get(key) !== value) {
        apply = true
        break
      }
    }
    if (apply) {
      router.push(pathname + "?" + createQueryString(options), {
        scroll: false,
      })
    }
  }

  return {
    searchParams,
    pathname,
    pushSearchParamsSelector,
  }
}
