"use client"

import { cleanStaleCartId } from "@local/utils"
import Cookies from "js-cookie"
import { nanoid } from "nanoid"

export const generateCartId = () => {
  cleanStaleCartId(Cookies.get("div-cart-short"))
  return nanoid()
}
