import type { CartBundleItem, CartItem } from "../../types"
import {
  getFirstVariantInStock,
  hasOptions,
  hasVariants,
  isBundle,
  getPricesFromProduct,
  getPrice,
} from ".."
import type { Payload } from "@local/payload-client/src/types"
import { Currency } from "@local/i10n"

export const getInitialCartByProduct = (
  mainProduct: Payload.Product,
  productMap: Map<Payload.Product["id"], Payload.Product>,
  initialQuantity: number,
  region: string,
  currency?: Currency,
  searchParams?: URLSearchParams | null,
  priceOverride?: string
): CartItem[] => {
  const cartItems: CartItem[] = []
  const prices = getPricesFromProduct(mainProduct, currency)
  if (isBundle(mainProduct) && mainProduct.bundleitem) {
    const bundleItems: CartBundleItem[] = []
    for (const bundleItem of mainProduct.bundleitem) {
      const product = productMap.get(bundleItem.product.id)
      if (product) {
        if (hasVariants(product) && hasOptions(product)) {
          const cartItem = getFirstVariantInStock({
            product,
            region,
            searchParams,
          }) as CartBundleItem
          for (let i = 1; i <= initialQuantity; i++) {
            if (cartItem) {
              bundleItems.push({
                cartKey: bundleItems.length + 1,
                productId: cartItem.productId,
                productName: cartItem.productName,
                preOrder: cartItem.preOrder,
                sku: cartItem.sku || null,
                quantity: 1,
                options: cartItem.options,
                variantId: cartItem.variantId,
                variantName: cartItem.variantName,
              })
            }
          }
        } else {
          bundleItems.push({
            cartKey: bundleItems.length + 1,
            productId: product.id,
            productName: product.name,
            preOrder: product.preorder,
            sku: product.sku || null,
            quantity: 1,
            options: [],
          })
        }
      }
    }
    for (let i = 1; i <= initialQuantity; i++) {
      cartItems.push({
        basePrice: getPrice(1, prices).basePrice,
        bundleItems: bundleItems,
        cartKey: cartItems.length + 1,
        options: [],
        price: getPrice(1, prices).price,
        priceOverride: priceOverride,
        preOrder: mainProduct.preorder,
        productId: mainProduct.id,
        productName: mainProduct.name,
        quantity: 1,
        sku: mainProduct.sku || null,
      })
    }
  } else {
    if (hasVariants(mainProduct) && hasOptions(mainProduct)) {
      const cartItem = getFirstVariantInStock({
        product: mainProduct,
        region,
        searchParams,
      })

      if (cartItem) {
        for (let i = 1; i <= initialQuantity; i++) {
          cartItems.push({
            basePrice: getPrice(1, prices).basePrice,
            cartKey: cartItems.length + 1,
            giftWrap: cartItem.giftWrap || null,
            image: cartItem.image,
            options: cartItem.options,
            preOrder: cartItem.preOrder,
            price: getPrice(1, prices).price,
            priceOverride: priceOverride,
            productId: cartItem.productId,
            productName: cartItem.productName,
            quantity: 1,
            sku: cartItem.sku || null,
            variantId: cartItem.variantId,
            variantName: cartItem.variantName,
          })
        }
      }
    } else {
      for (let i = 1; i <= initialQuantity; i++) {
        cartItems.push({
          basePrice: getPrice(1, prices).basePrice,
          cartKey: cartItems.length + 1,
          giftWrap: mainProduct.giftwrap,
          image: mainProduct.images[0].image.url,
          options: [],
          preOrder: mainProduct.preorder,
          price: getPrice(1, prices).price,
          priceOverride: priceOverride,
          productId: mainProduct.id,
          productName: mainProduct.name,
          quantity: 1,
          sku: mainProduct.sku || null,
        })
      }
    }
  }

  return cartItems
}
