import type { Payload } from "@local/payload-client/src/types"

export const isBundle = (product?: Payload.Product) => {
  return (
    product &&
    product.type === "bundle" &&
    product.bundleitem &&
    product.bundleitem.length > 0
  )
}
